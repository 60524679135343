//! 1 day = 86400 seconds
export const TimeWindow = {
  //! de -> 2 hours / 7200 seconds for time before cancel
  DE: [
    {
      timeToAdd: 0,
      myEventTimeBeforeCancel: 7200,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! nl -> 1 day / 24 hours / 86400 seconds
  //! nl -> 1 day / 24 hours / 86400 seconds for time before cancel

  NL: [
    {
      timeToAdd: 86400,
      myEventTimeBeforeCancel: 86400,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! ch -> 3 days / 216 hours / 259200 seconds
  //! ch -> 7 days / 168 hours / 604800 seconds for time before cancel
  CH: [
    {
      timeToAdd: 259200,
      myEventTimeBeforeCancel: 604800,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! at -> 3 days / 72 hours / 259200 seconds
  //! at -> 2 hours / 7200 seconds for time before cancel
  AT: [
    {
      timeToAdd: 259200,
      myEventTimeBeforeCancel: 7200,
      calendarEventSearchWeeks: 12,
    },
  ],
  GB: [
    {
      timeToAdd: 5,
      myEventTimeBeforeCancel: 0,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! es -> 0 second
  //! es -> 0 second for time before cancel
  ES: [
    {
      timeToAdd: 0,
      myEventTimeBeforeCancel: 0,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! fi -> 2 days / 48 hours / 172800 seconds
  //! fi -> 2 days / 48 hours / 172800 seconds for time before cancel
  FI: [
    {
      timeToAdd: 172800,
      myEventTimeBeforeCancel: 172800,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! za -> 1 day / 24 hours / 86400 seconds
  //! za -> 1 day / 24 hours / 86400 seconds for time before cancel
  ZA: [
    {
      timeToAdd: 86400,
      myEventTimeBeforeCancel: 86400,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! se -> 1 day / 24 hours / 86400 seconds
  //! se -> 1 day / 24 hours / 86400 seconds for time before cancel
  SE: [
    {
      timeToAdd: 86400,
      myEventTimeBeforeCancel: 0,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! dk -> 1 day / 24 hours / 86400 seconds
  //! dk -> 0 days / 5 hours / 18000 seconds for time before cancel
  DK: [
    {
      timeToAdd: 86400,
      myEventTimeBeforeCancel: 18000,
      calendarEventSearchWeeks: 12,
    },
  ],
  PL: [
    {
      timeToAdd: 0,
      myEventTimeBeforeCancel: 0,
      calendarEventSearchWeeks: 12,
    },
  ],
  PT: [
    {
      timeToAdd: 0,
      myEventTimeBeforeCancel: 0,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! gr -> 2 day / 48 hours / 172800 seconds
  GR: [
    {
      timeToAdd: 172800,
      myEventTimeBeforeCancel: 0,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! il -> 2 day / 48 hours / 172800 seconds
  IL: [
    {
      timeToAdd: 172800,
      myEventTimeBeforeCancel: 0,
      calendarEventSearchWeeks: 12,
    },
  ],
  //! no -> 2 day / 48 hours / 172800 seconds
  NO: [
    {
      timeToAdd: 172800,
      myEventTimeBeforeCancel: 0,
      calendarEventSearchWeeks: 12,
    },
  ],
};
