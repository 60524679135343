export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '3',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '3.1',
        title: 'cvent.cards.third_step.title',
        label: 'cvent.cards.third_step.button',
        image: '/workflow/special-training.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.third_step.description',
        disabled: false,
      },
      {
        from: '1',
        // to: 'Summmary',
        title: 'cvent.cards.second_step.title',
        label: 'cvent.cards.second_step.button',
        image: '/workflow/guardian.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.second_step.description',
        disabled: false,
        skipFlow: true,
        flowTags: [
          {
            key: 'Therapy',
            value: 'Webinars',
          },
        ],
      },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: '',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '3',
    centered: true,
    stepName: 'cvent.steps.cvent_check_experience',
    stepDescription: 'cvent.steps.cvent_check_experience',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '3',
        to: '9',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: '',
      },
      {
        from: '3',
        to: '8',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '3.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_experience',
    stepDescription: 'cvent.steps.cvent_check_experience',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '3.1',
        to: '11',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: '',
      },
      {
        from: '3.1',
        to: '10',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.se_representative',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_entry_level',
    componentType: 'RadioSelection',
    options: [
      {
        from: '5',
        to: '7',
        title: 'cvent.steps.training_pump',
        label: '',
      },
      {
        from: '5',
        to: 'Summary',
        title: 'cvent.steps.training_smartGuard',
        label: '',
      },
      {
        from: '5',
        to: 'Summary',
        title: 'cvent.steps.training_carelink',
        label: '',
      },
      {
        from: '5',
        to: 'Summary',
        title: 'cvent.steps.training_smartPen',
        label: '',
      },
    ],
  },
  {
    stepNumber: '7',
    centered: true,
    stepName: 'cvent.steps.medtronic_age_question',
    stepDescription: 'cvent.steps.medtronic_age_question',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '7',
        to: 'Summary',
        title: 'cvent.steps.cvent_check_if_age_under_17',
        label: '',
      },
      {
        from: '7',
        to: 'Summary',
        title: 'cvent.steps.cvent_check_if_age_above_18',
        label: '',
      },
    ],
  },
  {
    stepNumber: '8',
    centered: true,
    stepName: 'cvent.steps.cvent_check_select_product',
    stepDescription: 'cvent.steps.cvent_check_select_product',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '8',
        to: '8.1',
        title: 'cvent.steps.cvent_check_option_carelink',
        label: '',
      },
      {
        from: '8',
        to: '8.2',
        title: 'cvent.steps.cvent_check_option_minimed',
        label: '',
      },
    ],
  },
  {
    stepNumber: '8.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_disclaimer_carelink',
    stepDescription: 'cvent.steps.cvent_check_disclaimer_carelink',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '8.1',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Therapy',
            value: '780G system',
          },
          {
            key: 'Training',
            value: 'Mobile App',
          },
        ],
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '8.2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_disclaimer_minimed',
    stepDescription: 'cvent.steps.cvent_check_disclaimer_minimed',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '8.2',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Therapy',
            value: '780G system',
          },
          {
            key: 'Training',
            value: '780G',
          },
        ],
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '9',
    centered: true,
    stepName: 'cvent.steps.cvent_check_select_training',
    stepDescription: 'cvent.steps.cvent_check_select_training',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '9',
        to: '9.1',
        title: 'cvent.steps.cvent_select_smartguard',
        label: '',
      },
      {
        from: '9',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Therapy',
            value: '780G system',
          },
          {
            key: 'Training',
            value: 'CareLink',
          },
        ],
        title: 'cvent.steps.cvent_select_carelink',
        label: '',
      },
    ],
  },
  {
    stepNumber: '9.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_disclaimer_smartguard',
    stepDescription: 'cvent.steps.cvent_check_disclaimer_smartguard',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '9.1',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Therapy',
            value: '780G system',
          },
          {
            key: 'Training',
            value: 'Smartguard',
          },
        ],
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '10',
    centered: true,
    stepName: 'cvent.steps.cvent_check_disclaimer_minimed780_no',
    stepDescription: 'cvent.steps.cvent_check_disclaimer_minimed780_no',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '10',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Therapy',
            value: 'Optimisation',
          },
          {
            key: 'Manual',
            value: 'No',
          },
        ],
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '11',
    centered: true,
    stepName: 'cvent.steps.cvent_training_info',
    stepDescription: 'cvent.steps.cvent_training_info',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '11',
        to: '11.1',
        title: 'cvent.steps.cvent_select_carelink_personal',
        label: '',
      },
      {
        from: '11',
        to: '11.2',
        title: 'cvent.steps.cvent_select_smartguard_personal',
        label: '',
      },
    ],
  },
  {
    stepNumber: '11.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_disclaimer_minimed780_yes',
    stepDescription: 'cvent.steps.cvent_check_disclaimer_minimed780_yes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '11.1',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Therapy',
            value: 'Optimisation',
          },
          {
            key: 'Training',
            value: 'CareLink',
          },
        ],
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '11.2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_disclaimer_minimed780_yes',
    stepDescription: 'cvent.steps.cvent_check_disclaimer_minimed780_yes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '11.2',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Therapy',
            value: 'Optimisation',
          },
          {
            key: 'Training',
            value: 'Smartguard',
          },
        ],
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
];
